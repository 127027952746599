import React from "react";
import styled from "styled-components";

function ToastBar(props) {
  return (
    <Container>
      <Wrapper key="container">{props.children}</Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  perspective: 500px;
`;

// const _Wrapper = posed.div({
//   enter: {
//     opacity: 1,
//     y: 0,
//     delay: 100,
//     transition: {
//       y: { type: "spring", stiffness: 700, damping: 20 },
//     },
//   },
//   exit: {
//     opacity: 0,
//     y: 30,
//   },
// });

const Wrapper = styled.div`
  font-size: 12px;
  background-color: rgba(12, 12, 12, 0.9);
  color: #eee;
  padding: 1rem 1.5rem;
  font-family: sans-serif;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  transform-origin: top center;

  animation: enter 0.3s ease-in-out forwards;

  z-index: 2000;

  @keyframes enter {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export default ToastBar;
