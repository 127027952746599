import { all, call, take, put, select } from "redux-saga/effects";
import * as measurements from "./measurements";
import * as views from "./views";
import { rilievoSelectorById } from "./rilievi";
import { activeRilievoIdSelector } from "./meta";

import qs from "query-string";
import { userLogout } from "./user";

import { persistor } from "./index";

function* logoutSaga() {
  yield take(userLogout);
  persistor.purge();
  window.location.reload();
}

function* defaultViewSaga() {
  const [a, b] = yield all([take("FETCH_VIEWS_SUCCEEDED")]);

  const { rilievoId } = a.payload;

  const { view: urlViewId } = qs.parse(window.location.search);

  if (typeof urlViewId === "undefined") {
    const { id_vista: defaultViewId } = yield select(
      rilievoSelectorById(rilievoId)
    );

    yield put(views.showView(defaultViewId));
  } else {
    yield put(views.showView(urlViewId));
  }
}

export default function* rootSaga() {
  yield all([
    // call(viewsLinkingSaga),
    call(measurements.saga),
    call(defaultViewSaga),
    call(logoutSaga),
  ]);
}
