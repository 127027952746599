import React from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";
import Tooltip from "rc-tooltip";

import Spinner from "components/Spinner";

const Btn = React.memo(
  React.forwardRef(function Btn(props, forwardedRef) {
    return (
      <Container
        ref={forwardedRef}
        {...props}
        onClick={props.disabled ? null : props.onClick}
        highlighted={props.highlighted || props.active}
        role={"button"}
        slim={props.slim}
      >
        {props.spinner && (
          <span>
            <Spinner inline white />
          </span>
        )}
        {props.spinner ||
          (props.icon && <i className="material-icons">{props.icon}</i>)}
        {props.children && props.children !== "" && (
          <span>{props.children}</span>
        )}
      </Container>
    );
  })
);

export default React.forwardRef(
  ({ tooltip, tooltipPlacement, ...props }, ref) => {
    if (tooltip) {
      return (
        <Tooltip
          overlay={tooltip}
          placement={tooltipPlacement || "top"}
          mouseEnterDelay={0.5}
          trigger={tooltip ? ["hover"] : []}
        >
          <Btn ref={ref} {...props} />
        </Tooltip>
      );
    }

    return <Btn ref={ref} {...props} />;
  }
);

const sizes = {
  big: 32,
  normal: 26,
  small: 22,
  smallest: 18,
};

const fontSizes = {
  big: 18,
  normal: 16,
  small: 14,
  smallest: 12,
};

const Container = styled.div`
  height: ${props => sizes[props.size || "normal"]}px;
  color: white;
  padding: 1.25rem;
  border-radius: 3px;

  letter-spacing: 0.05em;

  text-transform: uppercase;
  font-weight: bold;

  cursor: pointer;

  font-size: .9em;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => `${props.width}px` || "auto"};

  i {
    font-size: ${props => fontSizes[props.size || "normal"]}px;
    line-height: 1;
  }

  &:hover {
    background-color: ${props => (props.noBg ? null : "#444")};
    color: white;
  }

  &:active {
    color: white;
    background-color: ${props => (props.noBg ? null : props.theme.high)};
    transform: translateY(1px);
  }

  > * + * {
    margin-left: .5rem;
  }

  ${props =>
    props.circle &&
    css`
      width: ${props => sizes[props.size || "normal"]}px;
      border-radius: 50%;
      padding: 0;
    `}

  ${props =>
    props.ghost &&
    css`
      border: 1px solid ${props => (props.active ? props.theme.high : "white")};
      background-color: transparent;

      &:hover {
        border-color: ${props => props.theme.high};
        background-color: ${props => props.theme.high};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;

      &:hover,
      &:active {
        background-color: initial !important;
        color: #999 !important;
      }
    `}

  ${props =>
    props.highlighted &&
    css`
      background-color: ${props => props.theme.high};
      color: white;

      &:hover {
        background-color: ${props => darken(0.1, props.theme.high)};
      }
    `}

  ${props => {
    const { color } = props;

    return (
      color &&
      css`
        background-color: ${props => props.theme[color]};
        color: white;

        &:hover {
          background-color: ${props => darken(0.1, props.theme[color])};
          color: white;
        }
      `
    );
  }}
`;
