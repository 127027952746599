import apiClient from "utility/apiClient";

export const DEFAULT_MARKER = {
  titolo: "Nuovo marker",
  permesso: "2",
  x: 0,
  y: 0,
  z: 0,
  alpha: 0,
  beta: 0,
  gamma: 0,
  crossTimeline: false,
  hover: false,
  visible: true,
  center: [0, 0, 0],
  rotation: [1, 1, 1],
  scale: [1, 1, 1],
  markerObjType: 0,
};

export const api = {
  fetch: ({ rilievoId }) =>
    apiClient()({
      action: "get_marker",
      data: {
        id_rilievo: rilievoId,
      },
    }).then(markers => markers.map(massageForStore)),
  set: marker => {
    return apiClient()({
      action: "set_marker",
      data: massageForAPI(marker),
    });
  },
  delete: ({ id }) =>
    apiClient()({
      action: "remove_marker",
      data: {
        id_marker: id,
      },
    }),
  update: ({ id, marker }) =>
    apiClient()({
      action: "set_marker",
      data: massageForAPI(marker),
    }),
  setPermissions: ({ id, permission }) =>
    apiClient({})({
      action: "permission_marker",
      data: {
        id_marker: id,
        permesso: permission,
      },
    }),
};

export function massageForStore(marker) {
  return {
    id: marker.id,
    titolo: marker.titolo,
    permesso: marker.permesso,
    x: parseFloat(marker.cord_x),
    y: parseFloat(marker.cord_y),
    z: parseFloat(marker.cord_z),
    alpha: parseFloat(marker.rot_x, 10),
    beta: parseFloat(marker.rot_y, 10),
    gamma: parseFloat(marker.rot_z, 10),
    rilievoId: marker.id_rilievo,
    visible: true,
    type: marker.tipo,
    author: marker.autore,
    crossTimeline: marker.cross_timeline === "1",
    scale: marker.scale.map(x => parseFloat(x)),
    created: new Date(marker.d_i),
    rotation:
      typeof marker.rotation === "object"
        ? marker.rotation.map(x => parseFloat(x))
        : [1, 1, 1],
    markerObjType: parseInt(marker.formato),
    link: marker.link && marker.link.valore,
  };
}

function err(message) {
  throw new Error(`[marker] ${message}`);
}

export function massageForAPI(marker) {
  const m = { ...DEFAULT_MARKER, ...marker };

  return {
    id_marker: m.id,
    id_rilievo: m.rilievoId || err("Missing rilievoId"),
    cord_x: m.x,
    cord_y: m.y,
    cord_z: m.z,
    center: JSON.stringify([m.x, m.y, m.z].map(parseFloat)),
    rot_x: m.alpha,
    rot_y: m.beta,
    rot_z: m.gamma,
    cross_timeline: m.crossTimeline ? "1" : "0",
    tipo: m.type,
    titolo: m.titolo,
    scale: JSON.stringify(m.scale.map(parseFloat)),
    rotation: JSON.stringify(m.rotation.map(parseFloat)),
    formato: marker.markerObjType,
  };
}
