import { createSelector } from "reselect";
import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";
import produce from "immer";

const api = {
  count: async () => {
    const { count, ...rest } = await apiClient()({
      action: "get_aggiornamenti",
      data: {
        peek: "1",
        count: "1",
      },
    });

    return { all: parseInt(count, 10), ...rest };
  },
  markAsRead: () =>
    apiClient()({
      action: "get_aggiornamenti",
    }),
  fetch: ({ projectId }) => {
    return apiClient({ cache: false })({
      action: "get_aggiornamenti",
      data: {
        peek: "1",
        id_progetto: projectId,
      },
    }).then(data => {
      /**
          tipo

          0= insert
          1=update
          2=delete
          3=citazione messaggio direct
          4=permessi
         */

      return data.map(x => {
        const {
          id,
          messaggio,
          id_rilievo,
          id_marker,
          id_misura,
          id_dato,
          id_utente,
          d_i,
        } = x;

        const item = {
          ...x,
          id,
          date: d_i,
          message: messaggio,
          userId: id_utente,
          rilievoId: id_rilievo,
        };

        if (id_misura) {
          item.type = "MEASUREMENT";
          item.itemId = id_misura;
        }

        if (id_marker) {
          item.type = "MARKER";
          item.itemId = id_marker;
        }

        if (id_dato) {
          item.type = "MARKERDATA";
          item.itemId = `${id_marker}/${id_dato}`;
        }

        return item;
      });
    });
  },
};

export const { notificationsCountSucceeded } = createActions(
  "NOTIFICATIONS_COUNT_SUCCEEDED"
);

export const fetchNotificationsCount = () => async dispatch => {
  const count = await api.count();
  dispatch(notificationsCountSucceeded({ count }));
};

export const markAllNotificationsAsRead = () => async dispatch => {
  await api.markAsRead();
  window.location.reload();
};

export const {
  fetchNotificationsRequest,
  fetchNotificationsSucceeded,
  fetchNotificationsFailed,
} = createActions(
  "FETCH_NOTIFICATIONS_REQUEST",
  "FETCH_NOTIFICATIONS_SUCCEEDED",
  "FETCH_NOTIFICATIONS_FAILED"
);

export const fetchNotifications = ({ projectId }) => async dispatch => {
  dispatch(fetchNotificationsRequest());

  try {
    const notifications = await api.fetch({ projectId });
    dispatch(fetchNotificationsSucceeded({ notifications }));
  } catch (err) {
    dispatch(fetchNotificationsFailed(err));
  }
};

export const reducer = handleActions(
  {
    [notificationsCountSucceeded]: (state, action) =>
      produce(state, draft => {
        draft.count = action.payload.count.all;
        draft.perProjectCount = action.payload.count;
      }),
    [fetchNotificationsRequest]: (state, action) =>
      produce(state, draft => {
        draft.data = [];
        draft.loading = true;
      }),
    [fetchNotificationsSucceeded]: (state, action) =>
      produce(state, draft => {
        const { notifications } = action.payload;

        draft.data = notifications;
        draft.loading = false;
      }),
  },
  {
    loading: false,
    data: [],
    count: 0,
    perProjectCount: {},
  }
);

export const notificationsSelector = state => state.notifications;

export const notificationsCountSelector = createSelector(
  notificationsSelector,
  notifications => notifications.count
);

export const notificationsSelectorByRilievoId = rilievoId =>
  createSelector(
    notificationsSelector,
    notifications =>
      notifications.filter(
        notification => notification && notification.rilievoId === rilievoId
      )
  );

export const notificationsSelectorByPublic = createSelector(
  notificationsSelector,
  notifications =>
    notifications.filter(notification => notification.pubblico !== "0")
);
