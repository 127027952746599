import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components/macro";
import { ConnectedRouter } from "connected-react-router";

import * as Sentry from "@sentry/browser";

import "rc-tooltip/assets/bootstrap.css";

import Login from "pages/Login";
import ResetPassword from "pages/ResetPassword";

import Root from "components/Root";
import LoggedRoute from "components/LoggedRoute";
import PageLoading from "components/PageLoading";

import { store, persistor, history } from "./store";

import DatGuiProvider from "components/DatGuiProvider";
import CacheBuster from "components/cache-buster";
import Toast, { ToastProvider } from "components/Toast/Toast";
import theme from "./theme";

import Permissions from "modules/Permissions";
import DocumentsIframe from "modules/DocumentsIframe";
import { fetchProjects, activeProjectSelector } from "store/projects";
import Modal from "components/Modal";
import ToastBar from "components/ToastBar";
import Btn from "components/Btn";

const Survey = React.lazy(() => import("modules/Survey"));
const PhotoInspection = React.lazy(() => import("modules/PhotoInspection"));

const UnsupportedBrowser = React.lazy(() =>
  import("components/UnsupportedBrowser")
);
const MapView = React.lazy(() => import("components/MapView"));
const Converter = React.lazy(() => import("pages/Converter"));
const MagicLogin = React.lazy(() => import("pages/magic-login"));
const Settings = React.lazy(() => import("pages/settings"));
const SurveyRouteHelper = React.lazy(() => import("pages/survey-route-helper"));
const Notifications = React.lazy(() => import("pages/notifications"));

function GenericMatch() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  const activeProject = useSelector(activeProjectSelector);

  if (!activeProject) {
    return <PageLoading />;
  }

  return <Redirect to={`/projects/${activeProject}`} />;
}

function LoggedInApp() {
  React.useEffect(() => {
    Sentry.init({
      dsn: "https://d031633931b045afa2f8d1219bc2a53f@sentry.io/1423237",
    });

    Sentry.configureScope(scope => {
      const { user } = store.getState();

      scope.setUser({
        id: user.userid,
        ...user,
      });
    });
  }, []);

  return (
    <CacheBuster>
      {({ isLatestVersion }) => (
        <Root>
          {!isLatestVersion && (
            <ToastBar>
              <div
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  & * + * {
                    margin-left: 1rem;
                  }
                `}
              >
                <span>
                  A new version of Primis Live is available. Press reload to
                  refresh the page and get the new version.
                </span>
                <div>
                  <Btn highlight ghost>
                    Reload
                  </Btn>
                </div>
              </div>
            </ToastBar>
          )}
          <Permissions />
          <DocumentsIframe />
          <Switch>
            <Route
              path="/surveys/:rilievoId"
              exact
              component={SurveyRouteHelper}
            />
            <Route
              path="/projects/notifications"
              exact
              component={Notifications}
            />
            <Route
              path="/projects/:projectId/notifications"
              exact
              component={Notifications}
            />
            <Route
              path="/projects/:projectId/:rilievoId/:markerId/photoinspection"
              exact
              component={PhotoInspection}
            />
            <Route
              path="/projects/:projectId/:rilievoId/:itemType?/:itemId?"
              exact
              component={Survey}
            />
            <Route path="/projects/:projectId" exact component={MapView} />
            <Route path="/settings" component={Settings} />
            <Route path="/converter" exact component={Converter} />
            <Route path="*" component={GenericMatch} />
          </Switch>
        </Root>
      )}
    </CacheBuster>
  );
}

function App(props) {
  const unsupported = typeof WebGL2RenderingContext === "undefined";

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <DatGuiProvider>
                <React.Suspense fallback={<PageLoading />}>
                  <Switch>
                    <Route path="/magic-login" exact component={MagicLogin} />
                    <Route path="/login" exact component={Login} />
                    <Route
                      path="/reset-password"
                      exact
                      component={ResetPassword}
                    />
                    <LoggedRoute path="/" component={LoggedInApp} />
                  </Switch>
                </React.Suspense>
              </DatGuiProvider>
            </ToastProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
