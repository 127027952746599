import { createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import produce from "immer";

import {
  SCENE_ITEMS,
  MOUSE_EVENT_TYPES,
  MOUSE_EVENT_MODIFIERS,
} from "components/defines";
import { markerSelectorById } from "./markers";
import { changeAction, setCursor, editItem } from "./meta";
import {
  editMarker,
  setMarkerHover,
  unsetMarkerHover,
  showTooltip,
  hideTooltip,
} from "./markers/meta";
import {
  setActiveMarkerTransformControls,
  setActiveObjTransformControls,
} from "./transform";
import { objectSelectorById } from "./objects";

/* -- actions -- */
export const { setCenterPoint, resetCenterPoint } = createActions({
  SET_CENTER_POINT: vector => ({ vector }),
  RESET_CENTER_POINT: undefined,
});

export const { setIntersected, resetIntersected } = createActions({
  SET_INTERSECTED: (id, type) => ({ id, type }),
  RESET_INTERSECTED: undefined,
});

export const { setClicked, resetClicked } = createActions({
  SET_CLICKED: (id, modifier) => ({ id, modifier }),
  RESET_CLICKED: undefined,
});

const marker3dEventHandler = props => (dispatch, getState) => {
  const { eventType, modifiers, marker } = props;
  const { shiftKey, metaKey } = modifiers;
  const { id, permesso } = marker;

  if (eventType === MOUSE_EVENT_TYPES.TAP) {
    if (shiftKey && permesso === "2") {
      dispatch(
        setActiveMarkerTransformControls({
          markerId: id,
        })
      );
    } else {
      dispatch(editMarker(id));
    }
  }
};

const marker2dEventHandler = props => (dispatch, getState) => {
  const { eventType, modifiers, marker } = props;
  const { shiftKey } = modifiers;
  const { id, type, permesso } = marker;

  if (eventType === MOUSE_EVENT_TYPES.TAP) {
    if (shiftKey && permesso === "2") {
      dispatch(
        changeAction({ name: "EDIT_MARKER_POSITION", data: { id, type } })
      );
    } else {
      dispatch(editMarker(id));
    }
  }
};

const markerEventHandler = props => (dispatch, getState) => {
  const { id, eventType, modifiers } = props;

  const marker = markerSelectorById(id)(getState());
  const { markerObjType } = marker;

  if (markerObjType === 0) {
    dispatch(marker2dEventHandler({ eventType, modifiers, marker }));
  } else {
    dispatch(marker3dEventHandler({ eventType, modifiers, marker }));
  }
};

const objEventHandler = props => (dispatch, getState) => {
  const { modifiers, id, eventType } = props;
  const { shiftKey, metaKey } = modifiers;

  const obj = objectSelectorById(id)(getState());

  if (!obj) return;

  if (eventType === MOUSE_EVENT_TYPES.TAP) {
    if (obj.permesso === "2" && shiftKey) {
      dispatch(
        setActiveObjTransformControls({
          objId: id,
        })
      );
    } else {
      dispatch(
        editItem({
          id: obj.id,
          type: "object",
        })
      );
    }
  }
};

export const handleRaycastClick = ev => (dispatch, getState) => {
  const intersected = raycasterIntersectedSelector(getState());
  const type = raycasterTypeSelector(getState());

  const { srcEvent, type: eventType } = ev;
  const { shiftKey, altKey, ctrlKey, metaKey } = srcEvent;
  const modifiers = { shiftKey, altKey, ctrlKey, metaKey };

  dispatch(setClickedByModifier(intersected, modifiers));

  if (intersected && type >= 0) {
    switch (type) {
      case SCENE_ITEMS.MARKER:
        dispatch(markerEventHandler({ id: intersected, eventType, modifiers }));
        break;
      case SCENE_ITEMS.OBJ:
        dispatch(objEventHandler({ id: intersected, eventType, modifiers }));
        break;
      case SCENE_ITEMS.MEASURE:
        break;
      default:
        break;
    }
  } else {
    dispatch(resetClicked());
  }
};

export const handleRaycastIntersect = (id, type, event) => (
  dispatch,
  getState
) => {
  if (id && type >= 0 && event) {
    const { shiftKey, metaKey } = event;

    dispatch(setIntersected(id, type));

    if (type === SCENE_ITEMS.MARKER) {
      const marker = markerSelectorById(id)(getState());
      const { markerObjType, permesso } = marker;
      dispatch(setMarkerHover({ markerId: id }));
      dispatch(showTooltip());

      const showPointer =
        markerObjType === 0 ||
        (markerObjType !== 0 && shiftKey && permesso === "2");

      if (showPointer) {
        dispatch(setCursor("pointer"));
      } else {
        dispatch(setCursor("default"));
      }
    } else if (type === SCENE_ITEMS.OBJ) {
      const obj = objectSelectorById(id)(getState());
      const { permesso } = obj;

      dispatch(unsetMarkerHover());

      const showPointer = (shiftKey && permesso === "2") || !shiftKey;

      if (showPointer) {
        dispatch(setCursor("pointer"));
      } else {
        dispatch(setCursor("default"));
      }
    } else if (type === SCENE_ITEMS.MEASURE) {
    } else {
    }
  } else {
    dispatch(resetClicked());
    dispatch(setCursor("default"));
    dispatch(resetIntersected());
    dispatch(unsetMarkerHover());
    dispatch(hideTooltip());
  }
};

const setClickedByModifier = (id, modifiers) => dispatch => {
  const { shiftKey, altKey, ctrlKey, metaKey } = modifiers;

  if (shiftKey) {
    dispatch(setClicked(id, MOUSE_EVENT_MODIFIERS.SHIFT));
  } else if (altKey) {
    dispatch(setClicked(id, MOUSE_EVENT_MODIFIERS.ALT));
  } else if (ctrlKey) {
    dispatch(setClicked(id, MOUSE_EVENT_MODIFIERS.CTRL));
  } else if (metaKey) {
    dispatch(setClicked(id, MOUSE_EVENT_MODIFIERS.META));
  } else {
    dispatch(setClicked(id, MOUSE_EVENT_MODIFIERS.DEFAULT));
  }
};

/* -- reducers -- */
export const reducer = handleActions(
  {
    [setIntersected]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { id, type } = payload;
        draft.intersected = id;
        draft.type = type;
      }),
    [resetIntersected]: (state, action) =>
      produce(state, draft => {
        draft.intersected = null;
        draft.type = null;
      }),
    [setClicked]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { id, modifier } = payload;
        Object.values(MOUSE_EVENT_MODIFIERS).forEach(value => {
          if (value === modifier) {
            draft.clicked[value] = id;
          } else {
            draft.clicked[value] = null;
          }
        });
      }),
    [resetClicked]: (state, action) =>
      produce(state, draft => {
        draft.clicked[MOUSE_EVENT_MODIFIERS.DEFAULT] = null;
        draft.clicked[MOUSE_EVENT_MODIFIERS.CTRL] = null;
        draft.clicked[MOUSE_EVENT_MODIFIERS.ALT] = null;
        draft.clicked[MOUSE_EVENT_MODIFIERS.META] = null;
        draft.clicked[MOUSE_EVENT_MODIFIERS.SHIFT] = null;
      }),
    [setCenterPoint]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { vector } = payload;
        draft.centerPoint = vector;
      }),
    [resetCenterPoint]: (state, action) =>
      produce(state, draft => {
        draft.centerPoint = null;
      }),
  },
  {
    intersected: null,
    clicked: {
      [MOUSE_EVENT_MODIFIERS.DEFAULT]: null,
      [MOUSE_EVENT_MODIFIERS.CTRL]: null,
      [MOUSE_EVENT_MODIFIERS.ALT]: null,
      [MOUSE_EVENT_MODIFIERS.META]: null,
      [MOUSE_EVENT_MODIFIERS.SHIFT]: null,
    },
    type: null,
    centerPoint: null,
  }
);

/* -- selectors -- */
const raycasterSelector = state => state.raycaster;

export const raycasterClickedSelector = createSelector(
  raycasterSelector,
  state => state.clicked
);

export const raycasterIntersectedSelector = createSelector(
  raycasterSelector,
  state => state.intersected
);

export const raycasterCenterPointSelector = createSelector(
  raycasterSelector,
  state => state.centerPoint
);

export const raycasterTypeSelector = createSelector(
  raycasterSelector,
  state => state.type
);
