import { createActions, handleActions } from "redux-actions";
import produce from "immer";

const Potree = window.Potree;

export const defaultClassifications = {
  0: {
    visible: true,
    name: "Created, never classified",
    color: "#FFFFFF",
  },
  1: {
    visible: true,
    name: "Unclassified",
    color: "#CCCCCC",
  },
  2: {
    visible: true,
    name: "Ground",
    color: "#996633",
  },
  3: {
    visible: true,
    name: "Low Vegetation",
    color: "#003300",
  },
  4: {
    visible: true,
    name: "Medium Vegetation",
    color: "#009900",
  },
  5: {
    visible: true,
    name: "High Vegetation",
    color: "#99ff99",
  },
  6: {
    visible: true,
    name: "Building",
    color: "#2ECC71",
  },
  7: {
    visible: true,
    name: "Low Point (noise)",
    color: "#444444",
  },
  8: {
    visible: true,
    name: "Reserved",
    color: "#cccccc",
    disabled: true,
  },
  9: {
    visible: true,
    name: "Water",
    color: "#3498DB",
  },
  10: {
    visible: true,
    name: "Rail",
    color: "#9B59B6",
  },
  11: {
    visible: true,
    name: "Raod Surface",
    color: "#999999",
  },
  12: {
    visible: true,
    name: "Reserved",
    color: "#cccccc",
    disabled: true,
  },
  13: {
    visible: true,
    name: "Wire - Guard (Shield)",
    color: "#F1C40F",
  },
  14: {
    visible: true,
    name: "Wire - Guard (Phase)",
    color: "#E67E22",
  },
  15: {
    visible: true,
    name: "Transmission Tower",
    color: "#E569FF",
  },
  16: {
    visible: true,
    name: "Wire-structure Connector",
    color: "#9999FF",
  },
  17: {
    visible: true,
    name: "Bridge Deck",
    color: "#F0DEA6",
  },
  18: {
    visible: true,
    name: "High Noise",
    color: "#555555",
  },
  DEFAULT: {
    color: "#cc00aa",
    disabled: true,
  },
};

/** -- actions -- **/
export const { updateSetting, mergeSettings } = createActions({
  UPDATE_SETTING: ({ name, value }) => ({ name, value }),
  MERGE_SETTINGS: ({ settings }) => ({ settings }),
});

export const { changeClassificationVisibility } = createActions({
  CHANGE_CLASSIFICATION_VISIBILITY: undefined,
});

/** -- reducers -- **/
export const classificationReducer = handleActions(
  {
    [changeClassificationVisibility]: state => {
      return produce(state, draft => {
        draft.visible = !draft.visible;
      });
    },
  },
  {
    name: "New Classification",
    visible: true,
    color: "#fc3224",
  }
);

export const reducer = handleActions(
  {
    [updateSetting]: (state, { payload: { name, value } }) => {
      return {
        ...state,
        [name]: value,
      };
    },
    [changeClassificationVisibility]: (state, action) => {
      const { payload } = action;
      const { negative } = payload;

      return produce(state, draft => {
        Object.keys(draft.classifications).forEach((key, i) => {
          console.log({ negative });

          if (key === payload.key) {
            if (!negative) {
              draft.classifications[key] = classificationReducer(
                draft.classifications[key],
                action
              );
            }
          } else {
            if (negative) {
              const invertedAction = produce(action, draft => {
                draft.payload.value = !draft.payload.value;
              });
              draft.classifications[key] = classificationReducer(
                draft.classifications[key],
                invertedAction
              );
            }
          }
        });
      });
    },
    [mergeSettings]: (state, { payload: { settings } }) => {
      return {
        ...state,
        ...settings,
      };
    },
  },
  {
    pointBudget: 1000 * 1000,
    edl: false,
    edlRadius: 0.1,
    edlStrength: 0.1,
    clipTask: Potree && Potree.ClipTask && Potree.ClipTask.HIGHLIGHT,
    useHQ: false,
    resolution: window.devicePixelRatio || 1,
    classifications: defaultClassifications,
  }
);

/** -- selectors -- **/
export const settingsSelector = state => state.settings;
