import { createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import produce from "immer";

import { changeAction } from "./meta";
import { api } from "./markers/lib";
import {
  updateTransformedMarker,
  markerSelectorById,
  disableMarkersVisibility,
} from "./markers";
import { updateWholeObject, objectSelectorById } from "./objects";

export const COSTANTS = {
  ROTATE: "rotate",
  TRANSLATE: "translate",
  SCALE: "scale",
  LOCAL: "local",
  WORLD: "world",
  ZOOM_IN: "zoom_in",
  ZOOM_OUT: "zoom_out",
};

/* -- actions -- */
export const {
  enableMarkerTransformControls,
  enableObjTransformControls,
  disableTransformControls,
  setTransformVectors,
} = createActions({
  ENABLE_MARKER_TRANSFORM_CONTROLS_: ({ markerId }) => ({ markerId }),
  ENABLE_OBJ_TRANSFORM_CONTROLS_: ({ objId }) => ({ objId }),
  DISABLE_TRANSFORM_CONTROLS: undefined,
  SET_TRANSFORM_VECTORS: ({ position, scale, rotation }) => ({
    position,
    scale,
    rotation,
  }),
});

export const { setTransform, setAxis, toggleAxis } = createActions({
  SET_TRANSFORM: transformation => ({ transformation }),
  SET_AXIS: axis => ({ axis }),
  TOGGLE_AXIS: undefined,
});

export const { zoomIn, zoomOut } = createActions({
  ZOOM_IN: undefined,
  ZOOM_OUT: undefined,
});

export const {
  updateTransformRequest,
  updateTransformSucceeded,
  updateTransformFailed,
} = createActions(
  "UPDATE_TRANSFORM_REQUEST",
  "UPDATE_TRANSFORM_SUCCEEDED",
  "UPDATE_TRANSFORM_FAILED"
);

export const setActiveMarkerTransformControls = ({ markerId }) => dispatch => {
  dispatch(
    changeAction({
      name: "TRANSFORM_MARKER",
      data: {},
    })
  );
  dispatch(enableMarkerTransformControls({ markerId }));
  dispatch(disableMarkersVisibility({ ignoreIds: [markerId] }));
};

export const setActiveObjTransformControls = ({ objId }) => (
  dispatch,
  getState
) => {
  const activeMarkerId = activeMarkerTransformControlsSelector(getState());
  const activeObjId = activeObjTransformControlsSelector(getState());

  if (activeMarkerId || activeObjId) return;

  dispatch(
    changeAction({
      name: "TRANSFORM_OBJ",
      data: {},
    })
  );
  dispatch(enableObjTransformControls({ objId }));
  dispatch(disableMarkersVisibility());
};

export const setInactiveTransformControls = () => dispatch => {
  dispatch(changeAction(null));
  dispatch(disableTransformControls());
};

export const saveNewMarkerVectors = () => async (dispatch, getState) => {
  dispatch(updateTransformRequest());

  try {
    const markerId = activeMarkerTransformControlsSelector(getState());
    const vectors = activeMarkerVectorsSelector(getState());

    const marker = markerSelectorById(markerId)(getState());
    const { position, rotation, scale } = vectors;

    const newMarker = { ...marker };

    newMarker.x = position.x;
    newMarker.y = position.y;
    newMarker.z = position.z;

    newMarker.alpha = rotation.x;
    newMarker.beta = rotation.y;
    newMarker.gamma = rotation.z;

    newMarker.rotation = [newMarker.alpha, newMarker.beta, newMarker.gamma];
    newMarker.scale = [scale.x, scale.y, scale.z];

    await api.update({ marker: newMarker });

    dispatch(updateTransformedMarker(newMarker));
    dispatch(updateTransformSucceeded());
    dispatch(setInactiveTransformControls());
  } catch (err) {
    console.log(err);
    dispatch(updateTransformFailed());
  }
};

export const saveNewObjVectors = () => async (dispatch, getState) => {
  dispatch(updateTransformRequest());

  try {
    const objId = activeObjTransformControlsSelector(getState());
    const vectors = activeMarkerVectorsSelector(getState());

    const refObj = objectSelectorById(objId)(getState());
    const obj = { ...refObj };
    const { position, rotation, scale } = vectors;

    obj.x = position.x;
    obj.y = position.y;
    obj.z = position.z;

    obj.alpha = rotation.x;
    obj.beta = rotation.y;
    obj.gamma = rotation.z;

    obj.scale = scale;

    dispatch(updateWholeObject(obj));
    dispatch(updateTransformSucceeded());
    dispatch(setInactiveTransformControls());
  } catch (err) {
    console.log(err);
    dispatch(updateTransformFailed());
  }
};

/* -- reducers -- */
export const reducer = handleActions(
  {
    [enableMarkerTransformControls]: (state, action) => {
      const { payload } = action;
      const { markerId } = payload;

      return produce(state, draft => {
        draft.markerId = markerId;
        draft.objId = null;
        draft.control.transformation = COSTANTS.TRANSLATE;
        draft.control.axis = COSTANTS.WORLD;
        draft.control.zoom = 0;
      });
    },
    [enableObjTransformControls]: (state, action) => {
      const { payload } = action;
      const { objId } = payload;

      return produce(state, draft => {
        draft.markerId = null;
        draft.objId = objId;
        draft.control.transformation = COSTANTS.TRANSLATE;
        draft.control.axis = COSTANTS.WORLD;
        draft.control.zoom = 0;
      });
    },
    [disableTransformControls]: (state, action) =>
      produce(state, draft => {
        draft.markerId = null;
        draft.objId = null;
        draft.vectors.scale = null;
        draft.vectors.rotation = null;
        draft.vectors.position = null;
        draft.control.transformation = null;
        draft.control.axis = null;
      }),
    [setTransformVectors]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { scale, rotation, position } = payload;

        draft.vectors.scale = scale;
        draft.vectors.rotation = rotation;
        draft.vectors.position = position;
      }),
    [setTransform]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { transformation } = payload;
        draft.control.transformation = transformation;
      }),
    [setAxis]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { axis } = payload;
        draft.control.axis = axis;
      }),
    [toggleAxis]: (state, action) =>
      produce(state, draft => {
        if (draft.control.axis === COSTANTS.LOCAL) {
          draft.control.axis = COSTANTS.WORLD;
        } else {
          draft.control.axis = COSTANTS.LOCAL;
        }
      }),
    [zoomIn]: (state, action) =>
      produce(state, draft => {
        draft.control.zoom = `${COSTANTS.ZOOM_IN}_${Math.random()}`;
      }),
    [zoomOut]: (state, action) =>
      produce(state, draft => {
        draft.control.zoom = `${COSTANTS.ZOOM_OUT}_${Math.random()}`;
      }),
    [updateTransformRequest]: (state, action) =>
      produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      }),
    [updateTransformSucceeded]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
        draft.error = false;
      }),
    [updateTransformFailed]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      }),
  },
  {
    objId: null,
    markerId: null,
    loading: false,
    error: false,
    vectors: {
      position: null,
      scale: null,
      rotation: null,
    },
    control: {
      transformation: null,
      axis: null,
      zoom: null,
    },
  }
);

/* -- selectors -- */
const metaSelector = state => state.meta;

export const transformControlsSelector = createSelector(
  metaSelector,
  state => state.transformControls
);

export const activeMarkerTransformControlsSelector = createSelector(
  transformControlsSelector,
  state => state.markerId
);

export const activeObjTransformControlsSelector = createSelector(
  transformControlsSelector,
  state => state.objId
);

export const activeMarkerVectorsSelector = createSelector(
  transformControlsSelector,
  state => state.vectors
);

export const activeControlsSelector = createSelector(
  transformControlsSelector,
  state => state.control
);
