import { createSelector } from "reselect";
import { actionSidebarSelector } from "../meta";

export const measurementsLoadingSelector = state => state.measurements.loading;

export const measurementsSelector = state => state.measurements.data;

export const visibleMeasurementsSelector = createSelector(
  measurementsSelector,
  measurements => measurements.filter(m => m.visible)
);

export const measurementSelectorById = id =>
  createSelector(
    measurementsSelector,
    measurements => measurements.find(measurement => measurement.id === id)
  );

export const measurementSelectorByTempId = tempId =>
  createSelector(
    measurementsSelector,
    measurements =>
      measurements.find(measurement => measurement.tempId === tempId)
  );

export const measurementSelectorByIdOrTempId = id =>
  createSelector(
    measurementsSelector,
    measurements =>
      measurements.find(
        measurement => measurement.tempId === id || measurement.id === id
      )
  );

export const getMeasurementBeingEdited = createSelector(
  measurementsSelector,
  actionSidebarSelector,
  (state, actionSidebar) => {
    if (!actionSidebar) return null;

    return state.find(x => x.id === actionSidebar.id);
  }
);

export const measurementsExportLinkSelector = state =>
  state.measurements.exportLinks;
