const envTheme =
  process.env.REACT_APP_THEME && JSON.parse(process.env.REACT_APP_THEME);

export default {
  white: "#ffffff",
  high: "#2F9EED",
  green: "#2ecc71",
  red: "#e74c3c",
  hg: "#56ecf6",
  hr: "#ecbcf2",
  gr: "#ecdc93",
  semitransparentBlack: "rgba(25, 24, 27, 0.8)",
  ...envTheme,
};
