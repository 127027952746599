import React from "react";

import styled from "styled-components/macro";

function Spinner({ white, inline, radius }) {
  function renderWrapper() {
    return (
      <Wrapper r={radius} white={white}>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 50">
          <path
            fill={white ? "#fff" : "#110F12"}
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
          />
        </svg>
      </Wrapper>
    );
  }

  if (inline) return renderWrapper();

  return <Container>{renderWrapper()}</Container>;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: ${props => (props.r ? `${props.r}px` : "1.4em")};
  width: ${props => (props.r ? `${props.r}px` : "1.4em")};

  svg rect,
  svg path {
    fill: ${props => (props.white ? "#fff" : props.theme.high)};
  }

  animation: spinner-rotation 1s infinite ease-in-out;

  transform-origin: center;

  @keyframes spinner-rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
