import React, {
  useReducer,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { produce } from "immer";
import styled from "styled-components";
import "styled-components/macro";

import Modal from "components/Modal";
import Sidekick from "components/Sidekick";
import Btn from "components/Btn";
import Table from "components/Table";
import { toastContext } from "components/Toast/Toast";

import { userSelector } from "store/user";
import {
  togglePermissionsWindow,
  permissionsWindowMetaSelector,
  fetchPermissions,
  permissionsSelector,
} from "store/permissions";
import { setPermissions } from "store/genericItem";

/*
id
nome
permission: 
    - 0 = niente;
    - 1 = lettura;
    - 2 = lettura e scrittura;
*/

function reducer(users = [], action) {
  const { type, payload } = action;
  if (type === "SET_PERMESSO") {
    const { id, toggle, value } = payload;

    return produce(users, draft => {
      const user = draft.find(item => item.id === id);
      if (user) {
        user.permission = toggle ? value : value - 1;
      }
    });
  }
  if (type === "SET_PERMESSO_TUTTI") {
    const { toggle, value } = payload;
    return produce(users, draft => {
      draft.forEach(
        (user, i) => (user.permission = toggle ? value : value - 1)
      );
    });
  }
  if (type === "SET_APPLICA_A_FIGLI") {
    const { id, toggle } = payload;
    return produce(users, draft => {
      const user = draft.find(item => item.id === id);
      if (user) {
        user.applyToChildren = toggle;
      }
    });
  }
  if (type === "SET_APPLICA_A_FIGLI_TUTTI") {
    const { toggle } = payload;
    return produce(users, draft => {
      draft.forEach((user, i) => (user.applyToChildren = toggle));
    });
  }
  return users;
}

function setPermesso(id, toggle, value) {
  return {
    type: "SET_PERMESSO",
    payload: {
      id,
      toggle,
      value,
    },
  };
}

function setPermessiTutti(toggle, value) {
  return {
    type: "SET_PERMESSO_TUTTI",
    payload: {
      toggle,
      value,
    },
  };
}

function setApplicaFigli(id, toggle, value) {
  return {
    type: "SET_APPLICA_A_FIGLI",
    payload: {
      id,
      toggle,
    },
  };
}

function setApplicaFigliTutti(toggle, value) {
  return {
    type: "SET_APPLICA_A_FIGLI_TUTTI",
    payload: {
      toggle,
    },
  };
}

export function Permissions(props) {
  const { onConfirm, onCancel, submitted, showApplyToChildren } = props;
  const [permissions, dispatch] = useReducer(reducer, props.permissions);

  const onCheckboxClick = (id, e) => {
    dispatch(setPermesso(id, e.target.checked, parseInt(e.target.value, 10)));
  };

  const onCheckboxTuttiClick = e => {
    dispatch(setPermessiTutti(e.target.checked, parseInt(e.target.value, 10)));
  };

  const onApplyChildrenCheckboxClick = (id, e) => {
    dispatch(setApplicaFigli(id, e.target.checked));
  };

  const onApplyChildrenCheckboxTuttiClick = e => {
    dispatch(setApplicaFigliTutti(e.target.checked));
  };

  const tuttiLettura = useMemo(() => {
    const filteredUsers =
      permissions && permissions.filter(user => user.permission >= 1);

    return filteredUsers && filteredUsers.length === permissions.length;
  }, [permissions]);

  const tuttiScrittura = useMemo(() => {
    const filteredUsers =
      permissions && permissions.filter(user => user.permission === 2);

    return filteredUsers && filteredUsers.length === permissions.length;
  }, [permissions]);

  const tuttiApplicaFigli = useMemo(() => {
    const filteredUsers =
      permissions && permissions.filter(user => user.applyToChildren === true);

    return filteredUsers && filteredUsers.length === permissions.length;
  }, [permissions]);

  return (
    <>
      <Sidekick.Title>Gestione permessi</Sidekick.Title>
      <Table smallPadding>
        <thead>
          <tr>
            <td width={showApplyToChildren ? "55%" : "60%"}>Nome</td>
            <td width={showApplyToChildren ? "15%" : "20%"}>Lettura</td>
            <td width={showApplyToChildren ? "15%" : "20%"}>Scrittura</td>
            {showApplyToChildren && <td width="15%">Applicato a figli</td>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Sidekick.SmallTitle noMargin>Tutti</Sidekick.SmallTitle>
            </td>
            <td>
              <input
                type="checkbox"
                value={1}
                onChange={onCheckboxTuttiClick}
                checked={tuttiLettura}
              />
            </td>
            <td>
              <input
                type="checkbox"
                value={2}
                onChange={onCheckboxTuttiClick}
                checked={tuttiScrittura}
              />
            </td>
            {showApplyToChildren && (
              <td>
                <input
                  type="checkbox"
                  value={0}
                  onChange={onApplyChildrenCheckboxTuttiClick}
                  checked={tuttiApplicaFigli}
                />
              </td>
            )}
          </tr>
          {permissions &&
            permissions.map(user => (
              <tr key={user.id}>
                <td>
                  <Sidekick.Label noPointer noMarginBottom size="big">
                    {user.nome}

                    {user.isAuthor && (
                      <Sidekick.Label noPointer noMarginBottom size="small">
                        Author
                      </Sidekick.Label>
                    )}
                  </Sidekick.Label>
                </td>
                <td>
                  <input
                    type="checkbox"
                    value={1}
                    onChange={e => onCheckboxClick(user.id, e)}
                    checked={user.permission >= 1}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    value={2}
                    onChange={e => onCheckboxClick(user.id, e)}
                    checked={user.permission >= 2}
                  />
                </td>
                {showApplyToChildren && (
                  <td>
                    <input
                      type="checkbox"
                      value={0}
                      onChange={e => onApplyChildrenCheckboxClick(user.id, e)}
                      checked={user.applyToChildren}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <ButtonsWrapper>
        <Btn onClick={onCancel}>Annulla</Btn>
        <Btn
          highlighted
          disabled={submitted}
          onClick={() => onConfirm(permissions)}
        >
          {submitted ? "Attendere" : "Conferma"}
        </Btn>
      </ButtonsWrapper>
    </>
  );
}

const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
`;

export default function PermissionsWithData(props) {
  const { sendToast } = React.useContext(toastContext);

  const userId = useSelector(userSelector);
  const permissions = useSelector(permissionsSelector);

  const { mounted, loading, submitted, id: itemId, itemType } = useSelector(
    permissionsWindowMetaSelector
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (mounted) {
      dispatch(fetchPermissions({ id: itemId, itemType }));
    }
  }, [mounted, dispatch]);

  const onConfirm = useCallback(
    async newPermissions => {
      try {
        await dispatch(
          setPermissions({
            id: itemId,
            itemType,
            permissions,
            newPermissions,
          })
        );

        dispatch(togglePermissionsWindow());
        sendToast({ type: "SUCCESS", message: `Permessi salvati` });
      } catch (err) {
        dispatch(togglePermissionsWindow());
        sendToast({
          type: "ERROR",
          message: `Errore durante il salvataggio`,
        });
      }
    },
    [itemId, itemType, permissions, dispatch]
  );

  const onCancel = useCallback(() => {
    dispatch(togglePermissionsWindow());
  }, [itemId, dispatch]);

  if (!mounted || itemId === 0) return null;

  // Mostrare il checkbox per applicare i permessi a tutti i figli solo se
  // itemType è progetto, rilievo o marker
  const showApplyToChildren = [0, 1, 2].includes(itemType);

  return (
    <Modal onCloseClick={() => dispatch(togglePermissionsWindow())}>
      {!loading && permissions && permissions.length > 0 ? (
        <Permissions
          permissions={permissions}
          userId={userId}
          onConfirm={onConfirm}
          onCancel={onCancel}
          submitted={submitted}
          showApplyToChildren={showApplyToChildren}
        />
      ) : (
        <Sidekick.Loading />
      )}
    </Modal>
  );
}
