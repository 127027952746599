import axios from "axios";
import Bluebird, { map } from "bluebird";

import throttle from "lodash.throttle";
import slugify from "utility/slugify";

Bluebird.config({
  cancellation: true,
});

function fixFileName(filename) {
  const [ext, ...name] = filename.split(".").reverse();

  return `${slugify(name.reverse())}.${ext}`;
}

const SERVICE_URL = "https://qay2mp14pd.execute-api.eu-central-1.amazonaws.com";

function sleep(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

const retry = (fn, ms = 1000, maxRetries = 5) =>
  new Promise((resolve, reject) => {
    var retries = 0;
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          console.log("retrying failed promise...");
          ++retries;
          if (retries == maxRetries) {
            return reject("maximum retries exceeded");
          }
          retry(fn, ms).then(resolve);
        }, ms);
      });
  });

class Part {
  constructor({
    id,
    part,
    file,
    onComplete,
    onProgress,
    onError,
    additionalPayload,
    slugify,
  }) {
    this.id = id;
    this.part = part;

    this.file = {
      name: file.name,
      type: file.type,
    };

    this.slugify = slugify;

    this.onComplete = onComplete;
    this.onProgress = onProgress;
    this.onError = onError;

    this.bytesUploaded = 0;
    this.additionalPayload = additionalPayload;
  }

  get progress() {
    return this.bytesUploaded / this.part.size;
  }

  promise = UploadId =>
    new Bluebird(async (resolve, reject, onCancel) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      onCancel(() => {
        source.cancel("Operation canceled by the user.");
        this.bytesUploaded = 0;
      });

      try {
        // get signed url for this part
        const { signedURL } = await axios({
          method: "POST",
          url: `${SERVICE_URL}/dev/multipart/upload-part`,
          data: {
            filename: this.slugify
              ? fixFileName(this.file.name)
              : this.file.name,
            part: this.id,
            UploadId,
            ...this.additionalPayload,
          },
        }).then(res => res.data);

        const attempt = async () => {
          console.log(this);

          const data = await axios.put(signedURL, this.part, {
            cancelToken: source.token,
            headers: { "Content-Type": this.file.type },
            onUploadProgress: x => {
              this.speed = x.loaded / x.timeStamp;

              this.bytesUploaded = x.loaded;
              this.onProgress();
            },
          });

          return data;
        };

        // puts file on bucket using the signed url
        const response = await retry(attempt, 5000, 10);

        // stores the etag from the response header, will be used to complete the multipart upload
        const etag = response.headers.etag;

        this.etag = etag.substring(1, etag.length - 1);

        this.onComplete(this);
        resolve();
      } catch (err) {
        this.onError(err);
      }
    });
}

class Upload {
  constructor({
    id,
    file,
    onProgress,
    onComplete,
    onError,
    additionalPayload,
    slugify,
    useSinglePart,
  }) {
    this.id = id;
    this.file = file;

    this.UploadId = null;

    this.parts = [];

    this.onProgress = onProgress;
    this.onComplete = onComplete;
    this.onError = onError;

    this.slugify = slugify;

    this.additionalPayload = additionalPayload;

    const PART_SIZE = useSinglePart ? this.file.size : 1024 * 1024 * 5; // 5MB min size, max size is 5TB;
    const NUM_PARTS = Math.floor(this.file.size / PART_SIZE) + 1;

    console.log({ PART_SIZE, NUM_PARTS, useSinglePart });

    for (let i = 1; i < NUM_PARTS + 1; i++) {
      const start = (i - 1) * PART_SIZE;
      const end = i * PART_SIZE;
      const part =
        i < NUM_PARTS ? this.file.slice(start, end) : this.file.slice(start);

      this.parts.push(
        new Part({
          id: i,
          part,
          file: this.file,
          onComplete: this.handlePartUpload,
          onProgress: this.onProgress,
          onError: this.onError,
          additionalPayload,
          slugify,
        })
      );
    }
  }

  get bytesUploaded() {
    return this.parts.reduce((bytes, part) => {
      return bytes + part.bytesUploaded;
    }, 0);
  }

  get progress() {
    return this.bytesUploaded / this.file.size;
  }

  handlePartUpload = () => {
    this.onProgress(this.progress);

    if (this.progress === 1) {
      this.complete();
    }
  };

  async getUploadId() {
    const { UploadId } = await axios({
      method: "POST",
      url: `${SERVICE_URL}/dev/multipart/start-upload`,
      data: {
        filename: this.slugify ? fixFileName(this.file.name) : this.file.name,
        ...this.additionalPayload,
      },
    }).then(res => res.data);

    this.UploadId = UploadId;
  }

  complete = throttle(async () => {
    this.response = await axios({
      method: "POST",
      url: `${SERVICE_URL}/dev/multipart/complete-upload`,
      data: {
        filename: this.slugify ? fixFileName(this.file.name) : this.file.name,
        UploadId: this.UploadId,
        parts: this.parts.map(part => ({
          ETag: part.etag,
          PartNumber: part.id,
        })),
        ...this.additionalPayload,
      },
    }).then(res => {
      return res.data;
    });

    this.uri = decodeURIComponent(this.response.Location);

    this.onProgress(this.progress);
  }, 1000);
}

class MultiUploader {
  constructor({
    files,
    onProgress,
    onComplete,
    onError,
    additionalPayload,
    slugify,
    useSinglePart,
  }) {
    this.uploads = files.map((file, i) => {
      console.log(file);

      const newPath = `${(file.path || "")
        .replace(file.name, "")
        .replace(/^\/|\/$/g, "")}`;

      return new Upload({
        id: i,
        file,
        slugify,
        useSinglePart,
        onProgress: progress => {
          this.handleProgress();
        },
        onError: onError,
        additionalPayload: { ...additionalPayload, path: newPath },
      });
    });

    this.onProgress = onProgress;
    this.onComplete = onComplete;
    this.onError = onError;

    this.started = false;
  }

  get totalBytes() {
    return this.uploads.reduce(
      (totalBytes, upload) => upload.file.size + totalBytes,
      0
    );
  }

  get progress() {
    return this.totalBytesUploaded / this.totalBytes;
  }

  get totalBytesUploaded() {
    return this.uploads.reduce((totalBytesUploaded, upload) => {
      return totalBytesUploaded + upload.bytesUploaded;
    }, 0);
  }

  async start() {
    // resolves all uploadIds
    await map(this.uploads, upload => upload.getUploadId(), {
      concurrency: 10,
    });

    this.allPartsPromises = this.uploads.reduce((allPartsPromises, upload) => {
      const { UploadId } = upload;
      const partsPromises = upload.parts.map(part => () =>
        part.promise(UploadId)
      );
      return [...allPartsPromises, ...partsPromises];
    }, []);

    this.started = true;

    this.resume();
    typeof this.onProgress === "function" &&
      this.onProgress(this.progress, this);
  }

  resume() {
    // do nothing if we didn't even start
    if (!this.started) return;

    this.uploadPromise = map(
      this.allPartsPromises,
      (promise, i) =>
        new Bluebird((resolve, reject, onCancel) => {
          if (promise === null) resolve();

          const pr = promise();

          pr.then(() => {
            this.allPartsPromises[i] = null;
            resolve();
          }).catch(() => {
            reject();
          });

          onCancel(() => {
            pr.cancel();
          });
        }),
      {
        concurrency: 10,
      }
    );
  }

  pause() {
    this.uploadPromise.cancel();
  }

  handleProgress = x => {
    typeof this.onProgress === "function" &&
      this.onProgress(this.progress, this);

    if (
      this.uploads.filter(upload => upload.response).length ===
      this.uploads.length
    ) {
      typeof this.onComplete === "function" && this.onComplete(this.uploads);
    }
  };
}

export default MultiUploader;

/**
 * Abstracts implementation details for simple file uploads
 */
export const simpleUpload = (files, additionalPayload) =>
  new Promise((resolve, reject) => {
    new MultiUploader({
      files,
      onComplete: resolve,
      onError: reject,
      additionalPayload,
    }).start();
  });
