import { createAction, createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import { combineReducers } from "redux";

import apiClient from "utility/apiClient";

import * as ROUTES from "const/routes";

import { reducer as markersMetaReducer } from "./markers/meta";
import { reducer as transformControlsReducer } from "./transform";
import { metaReducer as usersMetaReducer } from "./users";

export const THREED = "3D";
export const TWOD = "2D";
export const DOCUMENTS = "DOCUMENTS";

/* -- actions -- */
export const {
  meta: {
    changeView,
    editItem,
    stopEditing,
    changeAction,
    toggleSidebar,
    setActiveRilievoId,
    setCursor,
    setCameraProjection,
    setCameraControls,
    toggleCollapsedNavigation,
  },
} = createActions({
  META: {
    CHANGE_VIEW: viewName => ({ viewName }),
    EDIT_ITEM: ({ type, id }) => ({ type, id }),
    STOP_EDITING: () => null,
    TOGGLE_SIDEBAR: sidebar => ({ sidebar }),
    CHANGE_ACTION: undefined,
    SET_ACTIVE_RILIEVO_ID: ({ rilievoId }) => ({ rilievoId }),
    SET_CURSOR: cursor => ({ cursor }),
    SET_CAMERA_PROJECTION: cameraMode => ({ cameraMode }),
    SET_CAMERA_CONTROLS: cameraControl => ({ cameraControl }),
    TOGGLE_COLLAPSED_NAVIGATION: undefined,
  },
});

export const openSurveyInNewWindow = rilievoId => async () => {
  const { id_progetto, permesso, ...survey } = await apiClient({})({
    action: "get_rilievo",
    data: {
      id_rilievo: rilievoId,
    },
  });

  if (parseInt(permesso) >= 1) {
    const route = ROUTES.survey({ projectId: id_progetto, survey });
    window.open(route, "_blank");
  } else {
    console.log("nope");
  }
};

export const resetMeta = createAction("RESET_META");

/* -- reducer -- */
export const viewReducer = handleActions(
  {
    [changeView]: (state, action) => {
      const {
        payload: { viewName },
      } = action;

      if ([THREED, TWOD, DOCUMENTS].indexOf(viewName) === -1) {
        console.error("[store/meta] Noop", action);
        return state;
      }

      return viewName;
    },
  },
  THREED
);

export const activeRilievoReducer = handleActions(
  {
    [setActiveRilievoId]: (state, { payload: { rilievoId } }) => {
      return rilievoId;
    },
  },
  null
);

export const sidebarsReducer = handleActions(
  {
    [editItem]: (state, { payload: { type, id } }) => {
      return {
        ...state,
        action: {
          type,
          id,
        },
      };
    },
    [stopEditing]: state => {
      return {
        ...state,
        action: null,
      };
    },
    [toggleSidebar]: (state, { payload: { sidebar } }) => {
      return {
        ...state,
        navigation: state.navigation === sidebar ? false : sidebar,
      };
    },
  },
  {
    navigation: null,
    action: null,
  }
);

export const actionsReducer = handleActions(
  {
    [changeAction]: (state, action) => {
      const { payload } = action;

      if (payload) {
        return payload;
      } else {
        return null;
      }
    },
  },
  null
);

export const cursorReducer = handleActions(
  {
    [setCursor]: (state, action) => {
      const { cursor } = action.payload;

      return cursor;
    },
  },
  "default"
);

export const cameraReducer = handleActions(
  {
    [setCameraProjection]: (state, action) => {
      const { cameraMode } = action.payload;

      return {
        projection: cameraMode,
        control: cameraMode === 0 ? "OrbitControls" : "EarthControls",
      };
    },
    [setCameraControls]: (state, action) => {
      const { cameraControl } = action.payload;

      return {
        ...state,
        control: cameraControl,
      };
    },
  },
  {
    projection: 1,
    control: "EarthControls",
  }
);

export const collapsedNavReducer = handleActions(
  {
    [toggleCollapsedNavigation]: state => !state,
  },
  false
);

export const reducer = (state, action) => {
  if (action.type === `${resetMeta}`) {
    return {
      view: THREED,
      sidebars: {
        navigation: null,
        action: null,
      },
      action: null,
      collapsedNav: false,
    };
  }

  return combineReducers({
    view: viewReducer,
    sidebars: sidebarsReducer,
    action: actionsReducer,
    activeRilievoId: activeRilievoReducer,
    transformControls: transformControlsReducer,
    markers: markersMetaReducer,
    users: usersMetaReducer,
    cursor: cursorReducer,
    camera: cameraReducer,
    collapsedNav: collapsedNavReducer,
  })(state, action);
};

/* -- selectors -- */
export const metaSelector = state => state.meta;

export const actionSelector = createSelector(
  metaSelector,
  state => state.action
);

export const activeViewSelector = createSelector(
  metaSelector,
  state => state.view
);

export const sidebarsSelector = createSelector(
  metaSelector,
  state => state.sidebars
);

export const actionSidebarSelector = createSelector(
  sidebarsSelector,
  state => state.action
);

export const navigationSidebarSelector = createSelector(
  sidebarsSelector,
  state => state.navigation
);

export const activeRilievoIdSelector = createSelector(
  metaSelector,
  state => state.activeRilievoId
);

export const cursorSelector = createSelector(metaSelector, meta => meta.cursor);

export const cameraProjectionSelector = createSelector(
  metaSelector,
  meta => meta.camera.projection
);

export const cameraControlsSelector = createSelector(
  metaSelector,
  meta => meta.camera.control
);

export const collapsedNavSelector = createSelector(
  metaSelector,
  meta => meta.collapsedNav
);
