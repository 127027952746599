import {
  createActions,
  createAction,
  handleActions,
  combineActions,
} from "redux-actions";
import debounce from "lodash.debounce";
import pick from "lodash.pick";
import omit from "lodash.omit";

import apiClient, { UPLOAD_ACTIONS, uploader } from "utility/apiClient";
import { createSelector } from "reselect";
import { settingsSelector, mergeSettings } from "./settings";
import { cloudsSelector, mergeCloudsSettings } from "./clouds";

import { updateRilievo } from "./rilievi";
import produce from "immer";
import { markersSelector, mergeMarkersSettings } from "./markers";
import { mergeMeasurementsSettings } from "./measurements/actions";
import { measurementsSelector } from "./measurements/selectors";
import { objectsSelector, mergeObjectsSettings } from "./objects";

import { userSelector } from "./user";

import { simpleUpload } from "components/ResumableUpload/Uploader";

function fixView(view) {
  try {
    return {
      ...view,
      dati:
        view.dati === "" ? {} : JSON.parse(view.dati.replace(/&quot;/g, '"')),
    };
  } catch (err) {
    console.error(err, view);

    return {
      ...view,
      dati: {},
    };
  }
}

function splitToCoords(string) {
  const [x, y, z] = string.split(",").map(x => parseFloat(x));

  return {
    x,
    y,
    z,
  };
}

/* -- actions -- */
export const fetchViews = ({ rilievoId }) => async dispatch => {
  dispatch(fetchViewsRequest({ rilievoId }));

  try {
    const views = await apiClient({ cache: false })({
      action: "get_viste",
      data: {
        id_rilievo: rilievoId,
      },
    }).then(views => {
      return views.map(view => {
        return {
          ...view,
          position: splitToCoords(view.position),
          target: splitToCoords(view.target),
          image: `${process.env.REACT_APP_IMGIX}/viste/${rilievoId}/${view.id}.jpg`,
        };
      });
    });

    dispatch(fetchViewsSucceeded({ views, rilievoId }));
  } catch (err) {
    console.log(err);
    dispatch(fetchViewsFailed(err));
  }
};

export const saveDefaultView = ({ rilievoId, viewId }) => async dispatch => {
  try {
    await apiClient({ cache: false })({
      action: "set_vista_default ",
      data: {
        id_vista: viewId,
      },
    });

    dispatch(updateRilievo({ rilievoId, name: "id_vista", value: viewId }));
  } catch (err) {
    console.log(err);
  }
};

export const {
  fetchViewsRequest,
  fetchViewsSucceeded,
  fetchViewsFailed,
} = createActions({
  FETCH_VIEWS_REQUEST: undefined,
  FETCH_VIEWS_SUCCEEDED: ({ views, rilievoId }) => {
    return {
      views: views.map(view => fixView({ ...view, rilievoId })),
      rilievoId,
    };
  },
  FETCH_VIEWS_FAILED: err => ({ err }),
});

export const createView = (view, rilievoId) => async (dispatch, getState) => {
  dispatch(createViewRequest(view));

  const state = getState();
  const settings = settingsSelector(state);
  const clouds = cloudsSelector(state).map(cloud =>
    pick(cloud, [
      "id",
      "visible",
      "pointSizeType",
      "pointSize",
      "pointShape",
      "pointColorType",
      "pointColor",
      "pointOpacity",
      "elevationRange",
      "rgbGamma",
      "rgbBrightness",
      "rgbContrast",
      "gradient",
    ])
  );

  const { clientId: clientid, userid, token } = userSelector(state);

  const rawObjs = objectsSelector(state);
  const objs = rawObjs.map(obj =>
    omit(obj, ["x", "y", "z", "scale", "alpha", "beta", "gamma"])
  );

  const markers = markersSelector(state).map(marker =>
    pick(marker, ["id", "visible"])
  );

  const measurements = measurementsSelector(state).map(measurement =>
    pick(measurement, ["id", "visible", "clip"])
  );

  view.position = { ...window.viewer.scene.view.position };
  view.target = { ...window.viewer.scene.view.getPivot() };

  try {
    // const { url: img } = await uploader(UPLOAD_ACTIONS.VIEW, { rilievoId })(
    //   view.image
    // );

    const [{ uri: img }] = await simpleUpload([view.image], {
      path: `views/${rilievoId}`,
      userid,
      clientid,
      token,
      bucket: "primis-files",
    });

    const id = await apiClient({ cache: false })({
      action: "set_vista",
      data: {
        id_rilievo: rilievoId,
        position_x: view.position.x,
        position_y: view.position.y,
        position_z: view.position.z,
        target_x: view.target.x,
        target_y: view.target.y,
        target_z: view.target.z,
        titolo: "New View",
        dati: JSON.stringify(
          { settings, clouds, measurements, markers, objs },
          null,
          "  "
        ),
        img,
      },
    });

    dispatch(
      createViewSucceeded({
        ...view,
        id,
        titolo: "New view",
        img,
        dati: { settings, clouds, measurements, markers, objs },
      })
    );
  } catch (err) {
    console.log(err);
    dispatch(createViewFailed(err));
  }
};

export const {
  createViewRequest,
  createViewSucceeded,
  createViewFailed,
  setViewVisibility,
  setViewHover,
} = createActions({
  CREATE_VIEW_REQUEST: undefined,
  CREATE_VIEW_SUCCEEDED: undefined,
  CREATE_VIEW_FAILED: undefined,
  SET_VIEW_VISIBILITY: undefined,
  SET_VIEW_HOVER: undefined,
});

export const {
  deleteViewRequest,
  deleteViewSucceeded,
  deleteViewFailed,
} = createActions({
  DELETE_VIEW_REQUEST: undefined,
  DELETE_VIEW_SUCCEEDED: undefined,
  DELETE_VIEW_FAILED: (id, err) => ({ id, err }),
});

export const deleteView = ({ id }) => async (dispatch, getState) => {
  dispatch(deleteViewRequest({ id }));

  try {
    await apiClient({ cache: false })({
      action: "remove_vista",
      data: {
        id_vista: id,
      },
    });

    dispatch(deleteViewSucceeded({ id }));
  } catch (err) {
    console.error(err);
    dispatch(deleteViewFailed(id, err));
  }
};

export const {
  updateViewRequest,
  updateViewSucceeded,
  updateViewFailed,
} = createActions({
  UPDATE_VIEW_REQUEST: undefined,
  UPDATE_VIEW_SUCCEEDED: undefined,
  UPDATE_VIEW_FAILED: (id, err) => ({ id, err }),
});

const update = debounce(async function update(dispatch, getState, id) {
  const view = viewSelectorById(id)(getState());

  const { settings, clouds, markers, measurements, objs } = view.dati;

  try {
    await apiClient({ cache: false })({
      action: "update_vista",
      data: {
        id_vista: id,
        ...view,
        position_x: view.position.x,
        position_y: view.position.y,
        position_z: view.position.z,
        target_x: view.target.x,
        target_y: view.target.y,
        target_z: view.target.z,
        dati: JSON.stringify(
          { settings, clouds, markers, measurements, objs },
          null,
          "  "
        ),
      },
    });

    dispatch(updateViewSucceeded({ id }));
  } catch (err) {
    console.log(err);
    dispatch(updateViewFailed({ id, err }));
  }
}, 1000);

export const updateView = ({ id, name, value }) => async (
  dispatch,
  getState
) => {
  dispatch(updateViewRequest({ id, name, value }));

  update(dispatch, getState, id);
};

// permissions
export const setViewPermissions = ({ id, permission }) => async dispatch => {
  try {
    await apiClient({
      useCache: false,
    })({
      action: "permission_view",
      data: {
        id_vista: id,
        permesso: permission,
      },
    });

    updateView({ id, name: "permission", value: permission });
  } catch (err) {
    console.error(err);
  }
};

export const showView = id => (dispatch, getState) => {
  const view = viewSelectorById(id)(getState());

  if (typeof view === "undefined") return;

  const { settings, clouds, markers, measurements, objs } = view.dati;

  if (settings) {
    dispatch(mergeSettings({ settings }));
  }

  if (clouds) {
    dispatch(mergeCloudsSettings({ settings: clouds }));
  }

  if (markers) {
    dispatch(mergeMarkersSettings({ settings: markers }));
  }

  if (measurements) {
    dispatch(mergeMeasurementsSettings({ settings: measurements }));
  }

  if (objs) {
    dispatch(
      mergeObjectsSettings({
        settings: objs.map(obj =>
          omit(obj, ["x", "y", "z", "scale", "alpha", "beta", "gamma"])
        ),
      })
    );
  }

  window.Potree.Utils.moveTo(window.viewer.scene, view.position, view.target);
};

export const setView = createAction("SET_VIEW_FROM_URL");

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchViews]: state =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [fetchViewsSucceeded]: (state, { payload: { views } }) =>
      produce(state, draft => {
        draft.loading = false;
        draft.data = views;
      }),
    [createViewRequest]: state =>
      produce(state, draft => {
        draft.saving = true;
      }),
    [createViewSucceeded]: (state, { payload }) =>
      produce(state, draft => {
        draft.data.push({ ...payload, permesso: "2" });
        draft.saving = false;
      }),
    [deleteViewSucceeded]: (state, { payload: { id } }) =>
      produce(state, draft => {
        draft.data = draft.data.filter(view => view.id !== id);
      }),
    [combineActions(
      setViewVisibility,
      deleteViewRequest,
      updateViewRequest,
      setViewHover
    )]: (state, action) => {
      const {
        payload: { id },
      } = action;

      return {
        ...state,
        data: state.data.map(item =>
          item.id === id ? viewReducer(item, action) : item
        ),
      };
    },
  },
  {
    data: [],
    saving: false,
    loading: false,
  }
);

const viewReducer = handleActions(
  {
    [setViewVisibility]: (state, { payload: { id, visible } }) => {
      return {
        ...state,
        visible,
      };
    },
    [setViewHover]: (state, { payload: { id, hover } }) => {
      return {
        ...state,
        hover,
      };
    },
    [updateViewRequest]: (state, { payload: { id, name, value } }) => {
      return {
        ...state,
        [name]: value,
      };
    },
    [deleteViewRequest]: state => {
      return {
        ...state,
        deleting: true,
      };
    },
  },
  {}
);

/* -- selectors -- */
export const viewsMetaSelector = state => state.views;

export const viewsSelector = createSelector(
  viewsMetaSelector,
  state => state.data
);

export const viewsSelectorByRilievoId = rilievoId =>
  createSelector(viewsSelector, views => views.filter(view => view));

export const viewSelectorById = id =>
  createSelector(viewsSelector, views => views.find(view => view.id === id));
