export const SCENE_ITEMS = {
  MARKER: 0,
  MEASURE: 1,
  OBJ: 2,
};

export const MOUSE_EVENT_TYPES = {
  PAN_LEFT: "panleft",
  PAN_RIGHT: "panright",
  TAP: "tap",
  PRESS: "press",
};

export const MOUSE_EVENT_MODIFIERS = {
  DEFAULT: "default",
  META: "meta",
  ALT: "alt",
  CTRL: "ctrl",
  SHIFT: "shift",
};
