import React from "react";
import { useDispatch } from "react-redux";

import { fetchNotificationsCount } from "store/notifications";

function Root({ children }) {
  const dispatch = useDispatch();

  function checkForNotifications() {
    dispatch(fetchNotificationsCount());
  }

  React.useEffect(() => {
    checkForNotifications();

    const timeout = setTimeout(() => {
      checkForNotifications();
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return children;
}

export default Root;
