import { createActions, handleActions, combineActions } from "redux-actions";

import apiClient, { UPLOAD_ACTIONS, uploader } from "utility/apiClient";
import { createSelector } from "reselect";
import { markerSelectorById } from "./markers";

import uniqby from "lodash.uniqby";

/* -- actions -- */

// fetch
export const fetchMarkerData = markerId => async (dispatch, geState) => {
  dispatch(fetchMarkerDataRequest(markerId));

  const marker = markerSelectorById(markerId)(geState());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_dati",
      data: {
        id_marker: markerId,
        // receives additional payload with each data point with attached file size
        get_size: marker && (marker.type === "6" || marker.type === "1") && 1,
      },
    });

    dispatch(fetchMarkerDataSucceeded({ markerId, data: [...data] }));
  } catch (err) {
    console.error(err);
    dispatch(fetchMarkerDataFailed(err));
  }
};

const {
  fetchMarkerDataRequest,
  fetchMarkerDataSucceeded,
  fetchMarkerDataFailed,
} = createActions({
  FETCH_MARKER_DATA_REQUEST: markerId => ({ markerId }),
  FETCH_MARKER_DATA_SUCCEEDED: ({ markerId, data }) => ({ markerId, data }),
  FETCH_MARKER_DATA_FAILED: ({ err }) => ({ err }),
});

// delete
const {
  deleteMarkerDataRequest,
  deleteMarkerDataSucceeded,
  deleteMarkerDataFailed,
} = createActions({
  DELETE_MARKER_DATA_REQUEST: ({ id }) => ({ id }),
  DELETE_MARKER_DATA_SUCCEEDED: ({ id }) => ({ id }),
  DELETE_MARKER_DATA_FAILED: ({ err }) => ({ err }),
});

export const deleteMarkerData = ({ id }) => async dispatch => {
  dispatch(deleteMarkerDataRequest({ id }));

  try {
    await apiClient({ cache: false })({
      action: "remove_dato",
      data: {
        id_dato: id,
      },
    });

    dispatch(deleteMarkerDataSucceeded({ id }));
  } catch (err) {
    console.error(err);
    dispatch(deleteMarkerDataFailed(err));
  }
};

export const deleteAllMarkerData = ({ markerId }) => (dispatch, getState) => {
  const markerDataIds = markerDataSelectorByMarkerId(markerId)(getState()).map(
    markerData => markerData.id
  );

  const confirmed = window.confirm(
    `Vuoi eliminare tutti i ${markerDataIds.length} elementi?`
  );

  if (confirmed) {
    markerDataIds.forEach(id => dispatch(deleteMarkerData({ id })));
  }
};

// create
export const {
  createMarkerDataPointRequest,
  createMarkerDataPointSucceeded,
  createMarkerDataPointFailed,
} = createActions({
  CREATE_MARKER_DATA_POINT_REQUEST: null,
  CREATE_MARKER_DATA_POINT_SUCCEEDED: markerDataPoint => ({ markerDataPoint }),
  CREATE_MARKER_DATA_POINT_FAILED: ({ err }) => ({ err }),
});

export const createMarkerDataPoint = ({
  values,
  markerId,
}) => async dispatch => {
  dispatch(createMarkerDataPointRequest({ values }));

  try {
    if (values.files) {
      const multiple = values.files.length > 1;

      values.files.forEach(async (file, index) => {
        const upload = await uploader(UPLOAD_ACTIONS.MARKER_DATA, { markerId })(
          file
        );

        const markerDataPoint = await apiClient({ cache: false })({
          action: "set_dato",
          data: {
            label: multiple ? `${values.label} ${index}` : values.label,
            valore: upload.url,
            id_marker: markerId,
          },
        });

        const fixed = {
          ...markerDataPoint,
          id: `${markerDataPoint.id}`,
          d_i: new Date().getTime(),
          permesso: "2",
        };

        dispatch(createMarkerDataPointSucceeded({ ...fixed, markerId }));
      });
    } else {
      const markerDataPoint = await apiClient({ cache: false })({
        action: "set_dato",
        data: {
          ...values,
          id_marker: markerId,
        },
      });

      const fixed = {
        ...markerDataPoint,
        id: `${markerDataPoint.id}`,
        d_i: new Date().getTime(),
        permesso: "2",
      };

      dispatch(createMarkerDataPointSucceeded({ ...fixed, markerId }));
    }
  } catch (err) {
    console.error(err);
    dispatch(createMarkerDataPointFailed(err));
  }
};

// reorder
const { reorderMarkerDataSucceeded } = createActions({
  REORDER_MARKER_DATA_SUCCEEDED: undefined,
});

export const reorderMarkerData = ({ from, to }) => async (
  dispatch,
  getState
) => {
  dispatch(reorderMarkerDataSucceeded({ from, to }));

  const newIds = markerDataSelector(getState())
    .map(item => item.id)
    .join(",");

  try {
    await apiClient({})({
      action: "ordina_dati",
      data: {
        id_dati: newIds,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

// edit
export const editMarkerDataPoint = ({
  values,
  id,
  markerId,
}) => async dispatch => {
  dispatch(editMarkerDataPointRequest({ values, id }));

  try {
    await apiClient({ cache: false })({
      action: "set_dato",
      data: {
        ...values,
        id_dato: id,
        id_marker: markerId,
      },
    });

    dispatch(editMarkerDataPointSucceeded({ values, id }));
  } catch (err) {
    console.error(err);
    dispatch(editMarkerDataPointFailed(err));
  }
};

const {
  editMarkerDataPointRequest,
  editMarkerDataPointSucceeded,
  editMarkerDataPointFailed,
} = createActions({
  EDIT_MARKER_DATA_POINT_REQUEST: ({ values, id }) => ({ values, id }),
  EDIT_MARKER_DATA_POINT_SUCCEEDED: ({ values, id }) => ({ values, id }),
  EDIT_MARKER_DATA_POINT_FAILED: ({ err }) => ({ err }),
});

// permissions
export const setMarkerDataPermissions = ({
  id,
  permission,
}) => async dispatch => {
  try {
    await apiClient({
      useCache: false,
    })({
      action: "permission_dato",
      data: {
        id_dato: id,
        permesso: permission,
      },
    });

    editMarkerDataPointRequest({ id, name: "permission", value: permission });
  } catch (err) {
    console.error(err);
  }
};

/* -- reducers -- */
export const reducer = handleActions(
  {
    [fetchMarkerDataRequest]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [fetchMarkerDataSucceeded]: (state, { payload: { markerId, data } }) => ({
      ...state,
      loading: false,
      data: uniqby(
        [...state.data, ...data.map(datum => ({ ...datum, markerId }))],
        datum => datum.id
      ),
    }),
    [fetchMarkerDataFailed]: (state, { payload: { err } }) => ({
      ...state,
      loading: false,
      error: err,
    }),
    [deleteMarkerDataSucceeded]: (state, { payload: { id } }) => {
      return {
        ...state,
        data: state.data.filter(x => x.id !== id),
      };
    },
    [reorderMarkerDataSucceeded]: (state, { payload: { from, to } }) => {
      const newData = [...state.data];

      newData.splice(to, 0, newData.splice(from, 1)[0]);

      return {
        ...state,
        data: newData,
      };
    },
    [createMarkerDataPointSucceeded]: (
      state,
      { payload: { markerDataPoint } }
    ) => {
      return {
        ...state,
        data: [...state.data, markerDataPoint],
      };
    },
    [combineActions(deleteMarkerDataRequest, editMarkerDataPointRequest)]: (
      state,
      action
    ) => {
      const { id } = action.payload;
      const indexOfItem = state.data.find(x => x.id === id);

      if (typeof indexOfItem === "undefined") {
        return {
          ...state,
          data: [...state.data, dataPointReducer(null, action)],
        };
      }

      return {
        ...state,
        data: state.data.map(x =>
          x.id === id ? dataPointReducer(x, action) : x
        ),
      };
    },
  },
  {
    data: [],
    loading: false,
  }
);

export const dataPointReducer = handleActions(
  {
    [editMarkerDataPointRequest]: (state, action) => {
      const { values } = action.payload;

      return {
        ...state,
        ...values,
      };
    },
    [deleteMarkerDataRequest]: (state, action) => {
      return {
        ...state,
        deleting: true,
      };
    },
    [createMarkerDataPoint]: (state, { payload }) => {
      return { ...state, payload };
    },
  },
  {
    permesso: "2",
  }
);

/* -- selectors -- */
export const markerDataSelector = state => state.markerData.data;

export const markerDataSelectorByMarkerId = markerId =>
  createSelector(markerDataSelector, markerData =>
    markerData.filter(x => x.markerId === markerId)
  );

export const markerDataSelectorById = markerDataId =>
  createSelector(markerDataSelector, markerData =>
    markerData.find(markerDatum => markerDatum.id === markerDataId)
  );
