import { createActions, handleActions, combineActions } from "redux-actions";
import { createSelector } from "reselect";

import apiClient from "utility/apiClient";
import produce from "immer";
import pick from "lodash.pick";
import { rilievoSelectorById } from "./rilievi";

function fix(apiFile) {
  const file = pick(apiFile, ["id", "url", "size"]);

  const { percorso } = apiFile;

  file.path = percorso === "" ? "/" : percorso;
  file.name = file.url.split("/").pop();

  return file;
}

const api = {
  fetch: async ({ timelineId }) => {
    const data = await apiClient()({
      action: "get_risorse",
      data: {
        id_timeline: timelineId,
      },
    });

    return data.map(fix);
  },
};

/** -- actions -- **/
export const { openIframeModal, closeIframeModal } = createActions({
  OPEN_IFRAME_MODAL: (src, title) => ({ src, title }),
  CLOSE_IFRAME_MODAL: null,
});

export const {
  fetchDocumentsRequest,
  fetchDocumentsSucceeded,
  fetchDocumentsFailed,
} = createActions(
  "FETCH_DOCUMENTS_REQUEST",
  "FETCH_DOCUMENTS_SUCCEEDED",
  "FETCH_DOCUMENTS_FAILED"
);

export const fetchDocuments = ({ rilievoId }) => async (dispatch, getState) => {
  const { id_timeline } = rilievoSelectorById(rilievoId)(getState());

  dispatch(fetchDocumentsRequest({ rilievoId }));

  try {
    const documents = await api.fetch({ rilievoId, timelineId: id_timeline });
    dispatch(fetchDocumentsSucceeded({ documents, rilievoId }));
  } catch (err) {
    dispatch(fetchDocumentsFailed({ err }));
  }
};

export const { createDocumentsSucceeded } = createActions(
  "CREATE_DOCUMENTS_SUCCEEDED"
);

function removeFileName(file, path) {
  // remove filename and trailing /
  const newPath = path.replace(file.name, "").replace(/\/$/, "");

  // any empty path is considered root
  if (newPath === "") return "/";
  return newPath;
}

export const createDocuments = ({ rilievoId, documents }) => async (
  dispatch,
  getState
) => {
  const rilievo = rilievoSelectorById(rilievoId)(getState());

  const payload = JSON.stringify(
    documents.map(doc => ({
      url: doc.url,
      percorso: removeFileName(doc, doc.path),
    }))
  );

  const { id_timeline } = rilievo;

  try {
    const documents = await apiClient()({
      action: "set_risorsa",
      data: {
        risorse: payload,
        id_timeline,
      },
    });

    dispatch(createDocumentsSucceeded({ documents: documents.map(fix) }));
  } catch (err) {
    console.error(err);
  }
};

const updateDocument = ({ rilievoId }) => async () => {
  await apiClient()({
    action: "set_risorse",
  });
};

export const {
  updateDocumentRequest,
  updateDocumentSucceeded,
  updateDocumentFailed,
} = createActions(
  "UPDATE_DOCUMENT_REQUEST",
  "UPDATE_DOCUMENT_SUCCEEDED",
  "UPDATE_DOCUMENT_FAILED"
);

/** -- reducer -- **/
export const reducer = handleActions(
  {
    [openIframeModal]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { src, title } = payload;
        if (src && title) {
          draft.iframe.src = src;
          draft.iframe.title = title;
          draft.iframe.visible = true;
        }
      }),
    [closeIframeModal]: (state, action) =>
      produce(state, draft => {
        draft.iframe.src = null;
        draft.iframe.visible = false;
      }),
    [fetchDocumentsRequest]: (state, action) =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [combineActions(fetchDocumentsSucceeded, createDocumentsSucceeded)]: (
      state,
      action
    ) =>
      produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.documents;
      }),
    [fetchDocumentsFailed]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
        draft.error = action.payload.err;
      }),
  },
  {
    loading: true,
    data: [],
    iframe: {
      src: null,
      title: null,
      visible: false,
    },
  }
);

/* -- selectors -- */
export const documentsSelector = state => state.documents;

export const documentsDataSelector = createSelector(
  documentsSelector,
  documents => documents.data
);

export const documentsIframeSelector = createSelector(
  documentsSelector,
  documents => documents.iframe
);

export const documentsLoadingSelector = createSelector(
  documentsSelector,
  documents => documents.loading
);
